<template>
    <modal-lateral ref="modalPrevisualizacion" titulo="Previsualización">
        <div class="row mx-0">
            <p class="col-12 text-general f-17 f-500">
                {{ cedis }}
            </p>
            <p class="col-12 text-general f-14 py-2">
                <template v-if="fecha_inicio && fecha_fin">
                    {{ formatearFecha(fecha_inicio, 'DD MMM YYYY hh:mm a') }}
                    {{ formatearFecha(fecha_fin, 'DD MMM YYYY hh:mm a') }}
                </template>
                <template v-else>
                    Sin fecha establecida
                </template>
            </p>
            <div class="row mx-0 align-items-center mt-2 mb-1">
                <i class="icon-link text-general f-20" />
                <div class="col px-2 text-general f-600">
                    {{ showEnlace }}
                </div>
            </div>
            <div class="row mx-0 align-items-center mt-2 mb-1 w-100">
                <div class="col px-2 text-general">
                    {{ nombre }}
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center pt-3 custom-scroll overflow-auto" style="height:calc(100vh - 126px);">
            <div class="col-auto px-0 text-center">
                <div class="position-relative d-middle-center">
                    <div class="border-image position-absolute">
                        <img :src="imagen" class="obj-cover br-4" style="max-width:245px;max-height:100%;" />
                    </div>
                    <!-- <img :src="imagen" class="position-absolute obj-cover" style="max-width:241px;top:255px;left:65px;" /> -->
                    <img :src="url_cellphone" style="max-width:100%;height:700px;" class="obj-cover" />
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return {
            nombre: '',
            fecha_inicio: null,
            fecha_fin: null,
            url_cellphone: '/img/otros/cellphone.png',
            imagen: 'https://c1.staticflickr.com/9/8436/7881871802_5a6cc29e98_z.jpg',
            redireccion: 0,
            cedis: ''
        }
    },
    computed: {
        showEnlace(){
            if(this.redireccion == 0) return 'Sin enlace'
            if(this.redireccion == 1) return 'Categoría'
            if(this.redireccion == 2) return 'Promoción'
            if(this.redireccion == 3) return 'Producto'
            return ''
        }
    },
    methods: {
        toggle({
            imagen,
            titulo,
            fecha_inicio,
            fecha_fin,
            redireccion,
            cedis,
        }){
            this.imagen = imagen
            this.nombre = titulo
            this.fecha_inicio = fecha_inicio
            this.fecha_fin = fecha_fin
            this.redireccion = redireccion
            this.cedis = cedis
            this.$refs.modalPrevisualizacion.toggle();
        },
    }
}
</script>
<style lang="scss" scoped>
.border-image{
    border: 3px solid white;
    border-radius: 4px;
}
</style>
